import {
    GET_TASK_PENDING,GET_TASK_ERROR,GET_TASK_SUCCESS,
    NEW_TASK_ERROR,NEW_TASK_PENDING,NEW_TASK_SUCCESS,
    DEL_TASK_ERROR,DEL_TASK_PENDING,DEL_TASK_SUCCESS,
    STATUS_TASK_ERROR,STATUS_TASK_PENDING,STATUS_TASK_SUCCESS
} from '../../actions/types/types';

const initialState = {
    task:[],
    loading:false
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_TASK_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_TASK_SUCCESS:
            return {
                ...state,
                task:payload.data,
                loading:false
            }
        case GET_TASK_ERROR:
            return {
                ...state,
                task:[],
                loading:false
            }
        case STATUS_TASK_PENDING: 
        return {
            ...state,
            loading: true
        }

        case STATUS_TASK_SUCCESS:
            return {
                ...state,
                loading:false
        }
        case STATUS_TASK_ERROR:
            return {
                ...state,
                loading:false
        }
        case NEW_TASK_PENDING: 
        return {
            ...state,
            loading: true
        }

        case NEW_TASK_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case NEW_TASK_ERROR:
            return {
                ...state,
                loading:false
        }
        case DEL_TASK_PENDING: 
        return {
            ...state,
            loading: true
        }

        case DEL_TASK_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case DEL_TASK_ERROR:
            return {
                ...state,
                loading:false
        }
            
        default:
            return state;
    }
}