import {
    GET_STATUS_ERROR,GET_STATUS_SUCCESS,GET_STATUS_PENDING,
    NEW_STATUS_ERROR,NEW_STATUS_SUCCESS,NEW_STATUS_PENDING,
    DEL_NOTICE_ERROR,DEL_NOTICE_PENDING,DEL_NOTICE_SUCCESS,
    STATUS_NOTICE_ERROR,STATUS_NOTICE_PENDING,STATUS_NOTICE_SUCCESS,
    GET_CLIENT_STATUS_PENDING,GET_CLIENT_STATUS_ERROR,GET_CLIENT_STATUS_SUCCESS
} from '../../actions/types/types';

const initialState = {
    status:[],
    statusClient:[],
    loading:false,
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_STATUS_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_STATUS_SUCCESS:
            return {
                ...state,
                status:payload.data,
                loading:false
            }
        case GET_STATUS_ERROR:
            return {
                ...state,
                status:[],
                loading:false
            }

        case GET_CLIENT_STATUS_PENDING: 
        return {
            ...state,
            loading: true
        }

        case GET_CLIENT_STATUS_SUCCESS:
            return {
                ...state,
                statusClient:payload.data,
                loading:false
            }
        case GET_CLIENT_STATUS_ERROR:
        return {
            ...state,
            statusClient:[],
            loading:false
        }

        case STATUS_NOTICE_PENDING: 
        return {
            ...state,
            loading: true
        }

        case STATUS_NOTICE_SUCCESS:
            return {
                ...state,
                loading:false
        }
        case STATUS_NOTICE_ERROR:
            return {
                ...state,
                loading:false
        }
        case NEW_STATUS_PENDING: 
        return {
            ...state,
            loading: true
        }
        case NEW_STATUS_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case NEW_STATUS_ERROR:
            return {
                ...state,
                loading:false
        }
        
        case DEL_NOTICE_PENDING: 
        return {
            ...state,
            loading: true
        }
        case DEL_NOTICE_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case DEL_NOTICE_ERROR:
            return {
                ...state,
                loading:false
        }
            
        default:
            return state;
    }
}