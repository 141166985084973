// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import customizer from "./customizer/";
import authReducer from "./auth";
import UserReducer from "./users/users";
import DashboardReducer from "./dashboard";
import CompanyReducer from "./company"
import TrainingReducer from "./training"
import NoticeReducer from "./notice"
import TaskReducer from "./task"
import DetourReducer from "./detour"
import PlanningReducer from "./planning"
import StatusReducer from "./status"

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   toastr: toastrReducer, // <- Mounted at toastr.
   auth: authReducer,
   dashboard:DashboardReducer,
   customizer: customizer,
   users:UserReducer,
   company:CompanyReducer,
   training:TrainingReducer,
   notice:NoticeReducer,
   task:TaskReducer,
   detour:DetourReducer,
   planning:PlanningReducer,
   status:StatusReducer
});

export default rootReducer;
