import API from './../../utils/api';
import setAuthToken from './utils/SetAuthToken';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,LOGIN_SUCCESS,LOGIN_PENDING,LOGIN_FAIL,LOGOUT
} from './types/types';
import {toastr} from 'react-redux-toastr';

//Register
export const register = ({name,email,password}) => async dispatch => {

    const config = {
        header:{
           'Content-Type': 'application/json'
        }
     }
     const body = JSON.stringify({name,email,password});
     try{

        const res = await API.post('/api/users',body,config);
        dispatch({
            type:REGISTER_SUCCESS,
            payload:res.data
        });
        dispatch(loadUser());

     }catch(err){
         const errors = err.response.data.errors;
         if(errors){
            errors.forEach(error => dispatch(console.log('error'+error.msg)));
         }
        dispatch({
            type:REGISTER_FAIL
        });
     }
     

}

// Load User
export const loadUser = () => async dispatch => {
    if(localStorage.token){
        setAuthToken(localStorage.token);
    }

    try{
        const config = {
            header:{
               'Content-Type': 'application/json'
            }
         }
        const res = await API.get('/api/auth','',config);
        dispatch({
            type:USER_LOADED,
            payload:res.data
        });
        
    }catch(err){
        console.log(err);
        //toastr.error('Acceso', 'Datos incorrectos', { position: 'top-right'});
        dispatch({
            type:AUTH_ERROR
        });
    }
}


// Login User
export const login = (users) => async dispatch => {

    const config = {
        header:{
           'Content-Type': 'application/json'
        }
     }
     dispatch({
        type:LOGIN_PENDING
    });
     //const body = JSON.stringify({email,password});
     //console.log(body);
     try{
        const res = await API.post('/api/auth',users,config);
        dispatch({
            type:LOGIN_SUCCESS,
            payload:res.data
        });
        dispatch(loadUser());

     }catch(err){
        // const errors = err.response.data.errors;
         console.log(err);
         /*if(errors){
            errors.forEach(error => console.log(error.msg));
         }*/
        toastr.error('Acceso', 'Datos incorrectos', { position: 'top-right'});
        dispatch({
            type:LOGIN_FAIL
        });
     }
}

// Logout
export const logout = () => dispatch => {
    dispatch({type: LOGOUT});
}