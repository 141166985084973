import {
    GET_TRAINIG_SUCCESS,GET_TRAINIG_ERROR,GET_TRAINIG_PENDING,
    STATUS_TRAINIG_ERROR,STATUS_TRAINIG_PENDING,STATUS_TRAINIG_SUCCESS, 
    NEW_TRAINIG_PENDING, NEW_TRAINIG_SUCCESS, NEW_TRAINIG_ERROR,
    DEL_TRAINIG_ERROR,DEL_TRAINIG_SUCCESS,DEL_TRAINIG_PENDING
} from '../../actions/types/types';

const initialState = {
    training:[],
    loading:false
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_TRAINIG_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_TRAINIG_SUCCESS:
            return {
                ...state,
                training:payload.data,
                loading:false
            }
        case GET_TRAINIG_ERROR:
            return {
                ...state,
                training:[],
                loading:false
            }
        case STATUS_TRAINIG_PENDING: 
        return {
            ...state,
            loading: true
        }

        case STATUS_TRAINIG_SUCCESS:
            return {
                ...state,
                loading:false
        }
        case STATUS_TRAINIG_ERROR:
            return {
                ...state,
                loading:false
        }
        case NEW_TRAINIG_PENDING: 
        return {
            ...state,
            loading: true
        }

        case NEW_TRAINIG_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case NEW_TRAINIG_ERROR:
            return {
                ...state,
                loading:false
        }
        case DEL_TRAINIG_PENDING: 
        return {
            ...state,
            loading: true
        }

        case DEL_TRAINIG_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case DEL_TRAINIG_ERROR:
            return {
                ...state,
                loading:false
        }
            
        default:
            return state;
    }
}