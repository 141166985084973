import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,LOGIN_SUCCESS,LOGIN_FAIL,LOGIN_PENDING,LOGOUT
} from '../actions/types/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: false,
    user: {
        category:{
            name:null,
            degree:3
        }
    },
    company:[]
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case LOGIN_PENDING: 
            return {
            ...state,
            loading: true
        }

        case USER_LOADED:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
                user:payload
            }

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
              //  isAuthenticated: false,
                loading: false
            }

        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                ...payload,
                isAuthenticated: false,
                loading: false
            }
        default:
            return state;
    }
}