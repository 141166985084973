import {
    GET_DETOUR_PENDING,GET_DETOUR_ERROR,GET_DETOUR_SUCCESS,
    NEW_DETOUR_ERROR,NEW_DETOUR_PENDING,NEW_DETOUR_SUCCESS,
    DEL_DETOUR_ERROR,DEL_DETOUR_PENDING,DEL_DETOUR_SUCCESS,
    STATUS_DETOUR_ERROR,STATUS_DETOUR_PENDING,STATUS_DETOUR_SUCCESS,
    SEARCH_TASK_SUCCESS,SEARCH_TASK_ERROR,SEARCH_TASK_PENDING,
    SEARCH_DETOUR_PENDING,SEARCH_DETOUR_ERROR,SEARCH_DETOUR_SUCCESS
} from '../../actions/types/types';

const initialState = {
    detour:[],
    tasklist:[],
    loading:false,
    detourlist:[]
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_DETOUR_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_DETOUR_SUCCESS:
            return {
                ...state,
                detour:payload,
                loading:false
            }
        case GET_DETOUR_ERROR:
            return {
                ...state,
                tasklist:[],
                loading:false
            }
        
        case SEARCH_TASK_PENDING: 
            return {
                ...state,
                loading: true
            }

        case SEARCH_TASK_SUCCESS:
            return {
                ...state,
                tasklist:payload,
                loading:false
        }
        case SEARCH_TASK_ERROR:
            return {
                ...state,
                tasklist:[],
                loading:false
        }

        case STATUS_DETOUR_PENDING: 
        return {
            ...state,
            loading: true
        }

        case STATUS_DETOUR_SUCCESS:
            return {
                ...state,
                loading:false
        }
        case STATUS_DETOUR_ERROR:
            return {
                ...state,
                loading:false
        }
        case NEW_DETOUR_PENDING: 
        return {
            ...state,
            loading: true
        }

        case NEW_DETOUR_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case NEW_DETOUR_ERROR:
            return {
                ...state,
                loading:false
        }
        case DEL_DETOUR_PENDING: 
        return {
            ...state,
            loading: true
        }

        case DEL_DETOUR_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case DEL_DETOUR_ERROR:
            return {
                ...state,
                loading:false
        }

        case SEARCH_DETOUR_PENDING: 
            return {
                ...state,
                loading: true
            }

        case SEARCH_DETOUR_SUCCESS:
            return {
                ...state,
                detourlist:payload,
                loading:false
        }
        case SEARCH_DETOUR_ERROR:
            return {
                ...state,
                detourlist:[],
                loading:false
        }
            
        default:
            return state;
    }
}