// import external modules
import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./redux/storeConfig/store";
import ReduxToastr from 'react-redux-toastr'
import { loadUser } from './redux/actions/auth';
import setAuthToken from './redux/actions/utils/SetAuthToken';
import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./components/spinner/spinner";

const LazyApp = lazy(() => import("./app/app"));

if(localStorage.token){
   setAuthToken(localStorage.token);
}

const App = () => {

   useEffect(() => {
      store.dispatch(loadUser());
   }, []);

   return (
      <Provider store={store}>
      <Suspense fallback={<Spinner />}>
      <LazyApp />
      <ReduxToastr
            timeOut={4000}
            newestOnTop={true}
            preventDuplicates
            position="top-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick/>
      </Suspense>
   </Provider>
   );
 }

 
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
registerServiceWorker();
