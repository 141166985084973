import {
    GET_NOTICE_PENDING,GET_NOTICE_ERROR,GET_NOTICE_SUCCESS,
    NEW_NOTICE_ERROR,NEW_NOTICE_PENDING,NEW_NOTICE_SUCCESS,
    DEL_NOTICE_ERROR,DEL_NOTICE_PENDING,DEL_NOTICE_SUCCESS,
    STATUS_NOTICE_ERROR,STATUS_NOTICE_PENDING,STATUS_NOTICE_SUCCESS
} from '../../actions/types/types';

const initialState = {
    notice:[],
    loading:false
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_NOTICE_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_NOTICE_SUCCESS:
            return {
                ...state,
                notice:payload.data,
                loading:false
            }
        case GET_NOTICE_ERROR:
            return {
                ...state,
                notice:[],
                loading:false
            }
        case STATUS_NOTICE_PENDING: 
        return {
            ...state,
            loading: true
        }

        case STATUS_NOTICE_SUCCESS:
            return {
                ...state,
                loading:false
        }
        case STATUS_NOTICE_ERROR:
            return {
                ...state,
                loading:false
        }
        case NEW_NOTICE_PENDING: 
        return {
            ...state,
            loading: true
        }

        case NEW_NOTICE_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case NEW_NOTICE_ERROR:
            return {
                ...state,
                loading:false
        }
        case DEL_NOTICE_PENDING: 
        return {
            ...state,
            loading: true
        }

        case DEL_NOTICE_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case DEL_NOTICE_ERROR:
            return {
                ...state,
                loading:false
        }
            
        default:
            return state;
    }
}