import axios from "axios";

//axios.defaults.baseURL = "http://ec2-18-206-97-114.compute-1.amazonaws.com:5000/";
//axios.defaults.baseURL = "http://ec2-18-206-97-114.compute-1.amazonaws.com/";
//axios.defaults.baseURL = "http://ec2-3-80-10-89.compute-1.amazonaws.com:5000/";


axios.defaults.baseURL = "http://panel.sanchezmontero.com.ar/";
//axios.defaults.withCredentials = true;
export default axios.create({
//  baseURL: "http://localhost:5000/",
 // responseType: "json"
});