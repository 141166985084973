// Event Action Types
export const ADD_EVENT = "ADD_EVENT";

// Chat Action Types
export const HANDLE_FILTER_USER = "HANDLE_FILTER_USER";
export const HANDLE_SELECT_USER = "HANDLE_SELECT_USER";
export const HANDLE_SEND_MESSAGE = "HANDLE_SEND_MESSAGE";

// Register Action Types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

// User Action Types
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

// Users Types
export const GET_USERS_PENDING = 'GET_USERS_PENDING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const STATUS_USERS_PENDING = 'STATUS_USERS_PENDING';
export const STATUS_USERS_SUCCESS = 'STATUS_USERS_SUCCESS';
export const STATUS_USERS_ERROR = 'STATUS_USERS_ERROR';

export const NEW_USERS_PENDING = 'NEW_USERS_PENDING';
export const NEW_USERS_SUCCESS = 'NEW_USERS_SUCCESS';
export const NEW_USERS_ERROR = 'NEW_USERS_ERROR';

export const EDIT_USERS_PENDING = 'EDIT_USERS_PENDING';
export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS';
export const EDIT_USERS_ERROR = 'EDIT_USERS_ERROR';

export const GET_USER_CATEGORY_PENDING = 'GET_USER_CATEGORY_PENDING';
export const GET_USER_CATEGORY_SUCCESS = 'GET_USER_CATEGORY_SUCCESS';
export const GET_USER_CATEGORY_ERROR = 'GET_USER_CATEGORY_ERROR';

export const GET_CATEGORY_ALL_PENDING = 'GET_CATEGORY_ALL_PENDING';
export const GET_CATEGORY_ALL_SUCCESS = 'GET_CATEGORY_ALL_SUCCESS';
export const GET_CATEGORY_ALL_ERROR = 'GET_CATEGORY_ALL_ERROR';

export const DEL_USER_PENDING = 'DEL_USER_PENDING';
export const DEL_USER_SUCCESS = 'DEL_USER_SUCCESS';
export const DEL_USER_ERROR = 'DEL_USER_ERROR';


// Company Types
export const GET_COMPANY_PENDING = 'GET_COMPANY_PENDING';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';

export const STATUS_COMPANY_PENDING = 'STATUS_COMPANY_PENDING';
export const STATUS_COMPANY_SUCCESS = 'STATUS_COMPANY_SUCCESS';
export const STATUS_COMPANY_ERROR = 'STATUS_COMPANY_ERROR';

export const NEW_COMPANY_PENDING = 'NEW_COMPANY_PENDING';
export const NEW_COMPANY_SUCCESS = 'NEW_COMPANY_SUCCESS';
export const NEW_COMPANY_ERROR = 'NEW_COMPANY_ERROR';

export const EDIT_COMPANY_PENDING = 'EDIT_COMPANY_PENDING';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';

export const SEARCH_COMPANY_PENDING = 'SEARCH_COMPANY_PENDING';
export const SEARCH_COMPANY_SUCCESS = 'SEARCH_COMPANY_SUCCESS';
export const SEARCH_COMPANY_ERROR = 'SEARCH_COMPANY_ERROR';

// Training Types
export const GET_TRAINIG_PENDING = 'GET_TRAINIG_PENDING';
export const GET_TRAINIG_SUCCESS = 'GET_TRAINIG_SUCCESS';
export const GET_TRAINIG_ERROR = 'GET_TRAINIG_ERROR';

export const STATUS_TRAINIG_PENDING = 'STATUS_TRAINIG_PENDING';
export const STATUS_TRAINIG_SUCCESS = 'STATUS_TRAINIG_SUCCESS';
export const STATUS_TRAINIG_ERROR = 'STATUS_TRAINIG_ERROR';

export const NEW_TRAINIG_PENDING = 'NEW_TRAINIG_PENDING';
export const NEW_TRAINIG_SUCCESS = 'NEW_TRAINIG_SUCCESS';
export const NEW_TRAINIG_ERROR = 'NEW_TRAINIG_ERROR';

export const EDIT_TRAINIG_PENDING = 'EDIT_TRAINIG_PENDING';
export const EDIT_TRAINIG_SUCCESS = 'EDIT_TRAINIG_SUCCESS';
export const EDIT_TRAINIG_ERROR = 'EDIT_TRAINIG_ERROR';

export const DEL_TRAINIG_PENDING = 'DEL_TRAINIG_PENDING';
export const DEL_TRAINIG_SUCCESS = 'DEL_TRAINIG_SUCCESS';
export const DEL_TRAINIG_ERROR = 'DEL_TRAINIG_ERROR';

// Notice Types
export const GET_NOTICE_PENDING = 'GET_NOTICE_PENDING';
export const GET_NOTICE_SUCCESS = 'GET_NOTICE_SUCCESS';
export const GET_NOTICE_ERROR = 'GET_NOTICE_ERROR';

export const STATUS_NOTICE_PENDING = 'STATUS_NOTICE_PENDING';
export const STATUS_NOTICE_SUCCESS = 'STATUS_NOTICE_SUCCESS';
export const STATUS_NOTICE_ERROR = 'STATUS_NOTICE_ERROR';

export const NEW_NOTICE_PENDING = 'NEW_NOTICE_PENDING';
export const NEW_NOTICE_SUCCESS = 'NEW_NOTICE_SUCCESS';
export const NEW_NOTICE_ERROR = 'NEW_NOTICE_ERROR';

export const EDIT_NOTICE_PENDING = 'EDIT_NOTICE_PENDING';
export const EDIT_NOTICE_SUCCESS = 'EDIT_NOTICE_SUCCESS';
export const EDIT_NOTICE_ERROR = 'EDIT_NOTICE_ERROR';

export const DEL_NOTICE_PENDING = 'DEL_NOTICE_PENDING';
export const DEL_NOTICE_SUCCESS = 'DEL_NOTICE_SUCCESS';
export const DEL_NOTICE_ERROR = 'DEL_NOTICE_ERROR';

// STATUS types
export const GET_STATUS_PENDING = 'GET_STATUS_PENDING';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_ERROR = 'GET_STATUS_ERROR';

export const GET_CLIENT_STATUS_PENDING = 'GET_CLIENT_STATUS_PENDING';
export const GET_CLIENT_STATUS_SUCCESS = 'GET_CLIENT_STATUS_SUCCESS';
export const GET_CLIENT_STATUS_ERROR = 'GET_CLIENT_STATUS_ERROR';

export const NEW_STATUS_PENDING = 'NEW_STATUS_PENDING';
export const NEW_STATUS_SUCCESS = 'NEW_STATUS_SUCCESS';
export const NEW_STATUS_ERROR = 'NEW_STATUS_ERROR';

export const EDIT_STATUS_PENDING = 'EDIT_STATUS_PENDING';
export const EDIT_STATUS_SUCCESS = 'EDIT_STATUS_SUCCESS';
export const EDIT_STATUS_ERROR = 'EDIT_STATUS_ERROR';

export const DEL_STATUS_PENDING = 'DEL_STATUS_PENDING';
export const DEL_STATUS_SUCCESS = 'DEL_STATUS_SUCCESS';
export const DEL_STATUS_ERROR = 'DEL_STATUS_ERROR';

// Task Types
export const GET_TASK_PENDING = 'GET_TASK_PENDING';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_ERROR = 'GET_TASK_ERROR';

export const STATUS_TASK_PENDING = 'STATUS_TASK_PENDING';
export const STATUS_TASK_SUCCESS = 'STATUS_TASK_SUCCESS';
export const STATUS_TASK_ERROR = 'STATUS_TASK_ERROR';

export const NEW_TASK_PENDING = 'NEW_TASK_PENDING';
export const NEW_TASK_SUCCESS = 'NEW_TASK_SUCCESS';
export const NEW_TASK_ERROR = 'NEW_TASK_ERROR';

export const EDIT_TASK_PENDING = 'EDIT_TASK_PENDING';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const EDIT_TASK_ERROR = 'EDIT_TASK_ERROR';

export const DEL_TASK_PENDING = 'DEL_TASK_PENDING';
export const DEL_TASK_SUCCESS = 'DEL_TASK_SUCCESS';
export const DEL_TASK_ERROR = 'DEL_TASK_ERROR';

export const SEARCH_TASK_PENDING = 'SEARCH_TASK_PENDING';
export const SEARCH_TASK_SUCCESS = 'SEARCH_TASK_SUCCESS';
export const SEARCH_TASK_ERROR = 'SEARCH_TASK_ERROR';

export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_PENDING = 'DASHBOARD_PENDING';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';

export const GET_DASHBOARD_EMPLOYEE_TASK_SUCCESS = 'GET_DASHBOARD_EMPLOYEE_TASK_SUCCESS';
export const GET_DASHBOARD_EMPLOYEE_TASK_PENDING = 'GET_DASHBOARD_EMPLOYEE_TASK_PENDING';
export const GET_DASHBOARD_EMPLOYEE_TASK_ERROR = 'GET_DASHBOARD_EMPLOYEE_TASK_ERROR';



// Detour Types
export const GET_DETOUR_PENDING = 'GET_DETOUR_PENDING';
export const GET_DETOUR_SUCCESS = 'GET_DETOUR_SUCCESS';
export const GET_DETOUR_ERROR = 'GET_DETOUR_ERROR';

export const SEARCH_DETOUR_PENDING = 'SEARCH_DETOUR_PENDING';
export const SEARCH_DETOUR_SUCCESS = 'SEARCH_DETOUR_SUCCESS';
export const SEARCH_DETOUR_ERROR = 'SEARCH_DETOUR_ERROR';

export const STATUS_DETOUR_PENDING = 'STATUS_DETOUR_PENDING';
export const STATUS_DETOUR_SUCCESS = 'STATUS_DETOUR_SUCCESS';
export const STATUS_DETOUR_ERROR = 'STATUS_DETOUR_ERROR';

export const NEW_DETOUR_PENDING = 'NEW_DETOUR_PENDING';
export const NEW_DETOUR_SUCCESS = 'NEW_DETOUR_SUCCESS';
export const NEW_DETOUR_ERROR = 'NEW_DETOUR_ERROR';

export const DEL_DETOUR_PENDING = 'DEL_DETOUR_PENDING';
export const DEL_DETOUR_SUCCESS = 'DEL_DETOUR_SUCCESS';
export const DEL_DETOUR_ERROR = 'DEL_DETOUR_ERROR';

// Planning types
export const GET_PLANNING_PENDING = 'GET_PLANNING_PENDING';
export const GET_PLANNING_SUCCESS = 'GET_PLANNING_SUCCESS';
export const GET_PLANNING_ERROR = 'GET_PLANNING_ERROR';

export const GET_CLIENT_PLANNING_PENDING = 'GET_CLIENT_PLANNING_PENDING';
export const GET_CLIENT_PLANNING_SUCCESS = 'GET_CLIENT_PLANNING_SUCCESS';
export const GET_CLIENT_PLANNING_ERROR = 'GET_CLIENT_PLANNING_ERROR';

export const EDIT_PLANNING_PENDING = 'EDIT_PLANNING_PENDING';
export const EDIT_PLANNING_SUCCESS = 'EDIT_PLANNING_SUCCESS';
export const EDIT_PLANNING_ERROR = 'EDIT_PLANNING_ERROR';

export const NEW_PLANNING_PENDING = 'NEW_PLANNING_PENDING';
export const NEW_PLANNING_SUCCESS = 'NEW_PLANNING_SUCCESS';
export const NEW_PLANNING_ERROR = 'NEW_PLANNING_ERROR';

export const DEL_PLANNING_PENDING = 'DEL_PLANNING_PENDING';
export const DEL_PLANNING_SUCCESS = 'DEL_PLANNING_SUCCESS';
export const DEL_PLANNING_ERROR = 'DEL_PLANNING_ERROR';

export const GET_PLANNING_DETAIL_PENDING = 'GET_PLANNING_DETAIL_PENDING';
export const GET_PLANNING_DETAIL_SUCCESS = 'GET_PLANNING_DETAIL_SUCCESS';
export const GET_PLANNING_DETAIL_ERROR = 'GET_PLANNING_DETAIL_ERROR';

export const GET_PLANNING_TABLE_PENDING = 'GET_PLANNING_TABLE_PENDING';
export const GET_PLANNING_TABLE_SUCCESS = 'GET_PLANNING_TABLE_SUCCESS';
export const GET_PLANNING_TABLE_ERROR = 'GET_PLANNING_TABLE_ERROR';

export const UPDATE_TASK_PENDING = 'UPDATE_TASK_PENDING';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';

export const GET_FILE_PENDING = 'GET_FILE_PENDING';
export const GET_FILE_ERROR = 'GET_FILE_ERROR';
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
