import {
    DASHBOARD_SUCCESS,DASHBOARD_PENDING,DASHBOARD_ERROR,
    GET_DASHBOARD_EMPLOYEE_TASK_SUCCESS,GET_DASHBOARD_EMPLOYEE_TASK_ERROR,GET_DASHBOARD_EMPLOYEE_TASK_PENDING
} from '../../actions/types/types';

const initialState = {
    VehicleCount:0,
    CategoryCount:0,
    VehicleLiveCount:0,
    VehicleMechanical:0,
    loading:false,
    loadingMenu:false,
    loadTask:false,
    TaskList:[]
}

export default function(state = initialState, action){
    const { type, payload } = action;
    switch(type){

        case DASHBOARD_PENDING: 
            return {
                ...state,
                loading: true,
            }

        case DASHBOARD_SUCCESS:
            return {
                ...state,
                ...payload,
                Dashboard:payload.data,
                loading:false
            }
        case DASHBOARD_ERROR:
            return {
                ...state,
                ...payload,
                Dashboard:{},
                loading:false
            }

        case GET_DASHBOARD_EMPLOYEE_TASK_PENDING: 
        return {
            ...state,
            loadTask: true,
        }

        case GET_DASHBOARD_EMPLOYEE_TASK_SUCCESS:
            return {
                ...state,
                TaskList:payload,
                loadTask:false
            }

        case GET_DASHBOARD_EMPLOYEE_TASK_ERROR:
            return {
                ...state,
                TaskList:[],
                loading:false
            }
        default:
            return state;
    }
}