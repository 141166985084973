import {
    GET_USERS_ERROR,GET_USERS_PENDING,GET_USERS_SUCCESS,
    STATUS_USERS_ERROR,STATUS_USERS_SUCCESS,STATUS_USERS_PENDING,
    NEW_USERS_SUCCESS,NEW_USERS_ERROR,NEW_USERS_PENDING,
    GET_USER_CATEGORY_ERROR,GET_USER_CATEGORY_SUCCESS,GET_USER_CATEGORY_PENDING,
    DEL_USER_ERROR,DEL_USER_SUCCESS,DEL_USER_PENDING,
    GET_CATEGORY_ALL_PENDING,GET_CATEGORY_ALL_ERROR,GET_CATEGORY_ALL_SUCCESS
} from '../../actions/types/types';

const initialState = {
    users:[],
    usersCategory:[],
    loading:false,
    userClient:[],
    userTechnical:[]
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_USERS_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_USERS_SUCCESS:
            return {
                ...state,
                ...payload,
                users:payload.data,
                loading:false
            }
        case GET_USERS_ERROR:
            return {
                ...state,
                ...payload,
                users:[],
                loading:false
            }
        case GET_CATEGORY_ALL_PENDING: 
        return {
            ...state,
            loading: true
        }
        case GET_CATEGORY_ALL_SUCCESS:
            let data;
            if(action.degree === 5){
                //CLIENT payload
                return {
                    ...state,
                    userClient:payload.data,
                    loading:false
                }

            }else if(action.degree === 3){
                // Technical payload
                return {
                    ...state,
                    userTechnical:payload.data,
                    loading:false
                }
            }
            return {...state};
            
        case GET_CATEGORY_ALL_ERROR:
            return {
                ...state,
                userClient:[],
                loading:false
            }
        case STATUS_USERS_PENDING: 
        return {
            ...state,
            loading: true
        }

        case STATUS_USERS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading:false
            }
        case STATUS_USERS_ERROR:
            return {
                ...state,
                ...payload,
                loading:false
            }
        case NEW_USERS_PENDING: 
        return {
            ...state,
            loading: true
        }
        case NEW_USERS_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case NEW_USERS_ERROR:
            return {
                ...state,
                ...payload,
                loading:false
            }
        case GET_USER_CATEGORY_PENDING: 
            return {
                ...state,
                loading: true
            }
        case GET_USER_CATEGORY_SUCCESS:
            return {
                ...state,
                usersCategory:payload,
                loading:false
            }
        case GET_USER_CATEGORY_ERROR:
            return {
                ...state,
                loading:false
        }
        case DEL_USER_PENDING: 
            return {
                ...state,
                loading: true
            }
        case DEL_USER_SUCCESS:
            return {
                ...state,
               // usersCategory:payload,
                loading:false
            }
        case DEL_USER_ERROR:
            return {
                ...state,
                loading:false
        }
            
        default:
            return state;
    }
}