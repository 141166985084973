import {
    GET_PLANNING_ERROR,GET_PLANNING_SUCCESS,GET_PLANNING_PENDING,
   NEW_PLANNING_PENDING,NEW_PLANNING_ERROR,NEW_PLANNING_SUCCESS,
   DEL_PLANNING_ERROR,DEL_PLANNING_PENDING,DEL_PLANNING_SUCCESS,
   GET_PLANNING_DETAIL_SUCCESS,GET_PLANNING_DETAIL_ERROR,GET_PLANNING_DETAIL_PENDING,
   GET_PLANNING_TABLE_PENDING,GET_PLANNING_TABLE_SUCCESS,GET_PLANNING_TABLE_ERROR,
   GET_CLIENT_PLANNING_PENDING,GET_CLIENT_PLANNING_ERROR,GET_CLIENT_PLANNING_SUCCESS,
   GET_FILE_PENDING,GET_FILE_ERROR,GET_FILE_SUCCESS,
} from '../../actions/types/types';

const initialState = {
    userTechnical:[],
    planningList:[],
    loading:false,
    planningDetail:{
        task:[],
        task2:[],
        company:{rs:''},
        createAt:null
    },
    planningTable:[],
    clientPlanning:{
        planning:[]
    }
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_PLANNING_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_PLANNING_SUCCESS:
            return {
                ...state,
                planningList:payload.data,
                loading:false
            }
        case GET_PLANNING_ERROR:
            return {
                ...state,
                planningList:[],
                loading:false
        }
        case GET_FILE_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_FILE_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case GET_FILE_ERROR:
        return {
            ...state,
            loading:false
        }

        case GET_CLIENT_PLANNING_PENDING: 
            return {
                ...state,
                loading: true
        }

        case GET_CLIENT_PLANNING_SUCCESS:
            return {
                ...state,
                clientPlanning:payload,
                loading:false
            }
        case GET_CLIENT_PLANNING_ERROR:
            return {
                ...state,
                clientPlanning:{
                    planning:[]
                },
                loading:false
            }

        case NEW_PLANNING_PENDING: 
        return {
            ...state,
            loading: true
        }

        case NEW_PLANNING_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case NEW_PLANNING_ERROR:
            return {
                ...state,
                loading:false
        }
        case DEL_PLANNING_PENDING: 
        return {
            ...state,
            loading: true
        }

        case DEL_PLANNING_SUCCESS:
            return {
            ...state,
            loading:false
        }
        case DEL_PLANNING_ERROR:
            return {
                ...state,
                loading:false
        }

        case GET_PLANNING_DETAIL_PENDING: 
        return {
            ...state,
            loading: true,
            planningDetail:initialState.planningDetail
        }
        case GET_PLANNING_DETAIL_SUCCESS:
            return {
            ...state,
            loading:false,
            planningDetail:payload,
        }
        case GET_PLANNING_DETAIL_ERROR:
            return {
                ...state,
                loading:false,
                planningDetail:initialState.planningDetail
        }
        case GET_PLANNING_TABLE_PENDING: 
        return {
            ...state,
            loading: true,
            planningTable:initialState.planningTable
        }
        case GET_PLANNING_TABLE_SUCCESS:
            return {
            ...state,
            loading:false,
            planningTable:payload,
        }
        case GET_PLANNING_TABLE_ERROR:
            return {
                ...state,
                loading:false,
                planningTable:initialState.planningTable
        }  
        default:
            return state;
    }
}