import {
    GET_COMPANY_ERROR,GET_COMPANY_PENDING,GET_COMPANY_SUCCESS,
    STATUS_COMPANY_ERROR,STATUS_COMPANY_PENDING,STATUS_COMPANY_SUCCESS,
    SEARCH_COMPANY_PENDING,SEARCH_COMPANY_SUCCESS,SEARCH_COMPANY_ERROR
} from '../../actions/types/types';

const initialState = {
    companies:[],
    companylist:[],
    loading:false
}

export default function(state= initialState, action){
    const { type, payload } = action;
    switch(type){

        case GET_COMPANY_PENDING: 
            return {
                ...state,
                loading: true
            }

        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                companies:payload.data,
                loading:false
            }
        case GET_COMPANY_ERROR:
            return {
                ...state,
                companies:[],
                loading:false
            }

        case SEARCH_COMPANY_PENDING: 
        return {
            ...state,
            loading: true
        }

        case SEARCH_COMPANY_SUCCESS:
            return {
            ...state,
            companylist:payload,
            loading:false
        }
        case SEARCH_COMPANY_ERROR:
            return {
            ...state,
            companylist:[],
            loading:false
        }
        
        case STATUS_COMPANY_PENDING: 
        return {
            ...state,
            loading: true
        }

        case STATUS_COMPANY_SUCCESS:
            return {
                ...state,
                loading:false
        }
        case STATUS_COMPANY_ERROR:
            return {
                ...state,
                loading:false
        }
            
        default:
            return state;
    }
}